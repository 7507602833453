import { request } from '@/utils/api_client/sreshine/';

interface Params {
  path: string;
  session: string;
}

export const post = async (params: Params) => {
  await request.post('/log', params);
};

//
// Vuetify
//
// コンポーネントの読み込みについて
//   - 必要なコンポーネントのみインポートする。(vite.config.tsで { autoImport: false } を指定する)
//   - インポートはmain関数で行う。(base_componentで個別にインポートすると、スタイルの優先度がGlobalCSSより上がってしまうため)
//
// CSSについて
//   - vuetify/styles は normalize & base をするものなので、読み込まない。
//   - コンポーネントごとのCSSは、コンポーネントをインポートしたときに、同時にインポートされる。
//   - 色や影のサイズなど、コンポーネント全体に影響する変数だけ、vite.config.tsから読み込むCSSで指定する。(assets/stylesheets/object/component/base_component/vuetify_settings.scss)
//   - vuetifyのスタイルの変更は、変数ではなく、object/component/base_componentにて、クラスを上書きする形で制御する。

import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import {
  VBanner,
  VBtn,
  VDialog,
  VExpansionPanel,
  VExpansionPanels,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VList,
  VListItem,
  VListItemTitle,
  VMenu,
  VNavigationDrawer,
  VSelect,
  VSnackbar,
  VTextarea,
  VTextField,
  VTooltip,
  VChip,
  VCombobox,
  VAutocomplete,
} from 'vuetify/components';

const components = {
  VBanner,
  VBtn,
  VDialog,
  VExpansionPanel,
  VExpansionPanels,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VList,
  VListItem,
  VListItemTitle,
  VMenu,
  VNavigationDrawer,
  VSelect,
  VSnackbar,
  VTextarea,
  VTextField,
  VTooltip,
  VChip,
  VCombobox,
  VAutocomplete,
};
const vuetify = createVuetify({
  components,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

export default vuetify;

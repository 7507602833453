<script lang="ts" setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { BaseSnackbar } from './assets/base_components/';
import { useHtmlMeta } from '@/hooks/utils/useHtmlMeta';
import { useAuthentication } from '@/hooks/auth/useAuthentication';
import { request } from '@/utils/api_client/sreshine';
const { isLoadingAuthState } = useAuthentication();
import { useLog } from '@/hooks/utils/useLog';

request.inject();
// routerの切り替えでhtmlのheadを更新する処理を登録
useHtmlMeta();

const logger = useLog();
const router = useRouter();
onMounted(() => {
  router.afterEach((to) => {
    logger.userBehavior(to.path);
  });
});
</script>
<template>
  <v-app>
    <template v-if="!isLoadingAuthState">
      <router-view />
    </template>
    <BaseSnackbar />
  </v-app>
</template>

import { post as postLog } from '@/api/apiSreshine/log';

export const useLog = () => {
  const userBehavior = async (path: string) => {
    // get uuid from session storage or create one if it doesn't exist
    const session = sessionStorage.getItem('session') || crypto.randomUUID();
    sessionStorage.setItem('session', session);
    postLog({
      path,
      session,
    });
  };
  return {
    userBehavior,
  };
};
